import {Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {routerTransition, slideToLeft} from "../../animations/router.animations";
import {ReportsService} from "../../service/reports.service.ts/reports.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ExampleHeader} from "../../shared/datapicker-header/datapicker-header";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {PhoneMaskService} from "../../service/phoneMask.service";
import {ResetPageScrollService} from "../../service/pageScroll/resetPageScroll";
import {ReportTransactions, ReportTransactionsItem} from "../../models/reportTransactions/reportTransactions";
import {environment} from "../../../environments/environment";
import {PaginationDataModel, SetPaginationDataModel} from "../../models/scrollPagination/paginationData.model";
import {PaginationData} from "../../models/scrollPagination/paginationData";
import {VendorService} from "../../service/game/vendors/vendor.service";
import {Router} from "@angular/router";
import {PaginationScrollService} from "../../service/pageScroll/paginationScroll.service";
import {BlurMenuService} from "../../service/blurMenu.service";
import {DATA_TYPES} from "../../data_types";
import {CollectionsService} from "../../service/collections/collections.service";
import {PIN_POPUP_TYPE} from "../../shared/pin-popup/pin-popup-config";
import {PAYMENTS_INFO_POPUP_TYPE} from "../../shared/payments-info-popup/payments-info-popup-config";
import {DatesService} from "../../service/dates.service";
import {PartnerService} from "../../service/partner.service";

@Component({
    selector: 'app-collections',
    templateUrl: './collections.component.html',
    styleUrls: ['./collections.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class CollectionsComponent implements OnInit {
    dateForm: FormGroup;
    exampleHeader = ExampleHeader;
    isRiverPay = this.partnerService.isRiverPay();

    showPaymentsPopup = false;
    paymentsPopupType: number;
    paymentsPopupDate: any;
    paymentsPopupData;

    showSettlePopup = false;
    settlePopupType: number;
    settlePopupData;

    selectedMonday;
    selectedSunday;
    selectedMonMonth;
    selectedSunMonth;
    selectedMonYear;
    selectedSunYear;

    settled: boolean;

    popupData;

    DATA_TYPES = DATA_TYPES;
    PIN_POPUP_TYPE = PIN_POPUP_TYPE;
    PAYMENTS_INFO_POPUP_TYPE = PAYMENTS_INFO_POPUP_TYPE;

    net = {};
    netTotal: number;
    location: any = {};
    feesData: any = {};
    licenceData: any = {};
    totalOwed: any = {};
    allTimeOwed: any = {};
    netData: any = {};

    settleTitle = {
        location: 'OWED TO LOCATION',
        vendor: 'OWED TO VENDOR'
    };
    partnerType;

    isMoreInfoPopup = false;
    moreInfoPopupData = {};
    moreInfoPopupTitle = '';
    feesDetailsData = [];
    licensingDetailsData = [];

    whiteLabelData = {
        show: false,
        whiteLabel: {percent: 0, amount: 0},
        inflows: {percent: 0, amount: 0},
        outflows: {percent: 0, amount: 0},
        total: {percent: 0, amount: 0}
    }
    constructor(private reportsService: ReportsService,
                private popupComponent: PopupComponent,
                private loaderService: LoaderService,
                private phoneMaskService: PhoneMaskService,
                private auth: AuthenticationService,
                private resetPageScrollService: ResetPageScrollService,
                private router: Router,
                private formBuilder: FormBuilder,
                private blurMenuService: BlurMenuService,
                private collectionsService: CollectionsService,
                private partnerService: PartnerService,
                private paginationScrollService: PaginationScrollService,
                private datesService: DatesService,
                private vendorService: VendorService,
                ) {
        const today = new Date();
        const month = today.getMonth();
        const year = today.getFullYear();

        this.dateForm = this.formBuilder.group({
            date: [new Date()],
        });
        this.partnerType = this.auth.getPartnerType();
    }

    ngOnInit() {
        setTimeout(() => {
            this.updateSelectedPeriod(new Date());
        });
    }

    updateSelectedPeriod(date) {
        this.getSliderDays(date);
        this.getData();
    }

    getSliderDays(date) {
        const selectedMondayFullDate = this.datesService.getMonday(date);
        const selectedSundayFullDate = this.datesService.getSunday(date);

        this.selectedMonday = selectedMondayFullDate.getDate();
        this.selectedMonMonth = selectedMondayFullDate.getMonth() + 1;
        this.selectedMonYear = selectedMondayFullDate.getFullYear();

        this.selectedSunday = selectedSundayFullDate.getDate();
        this.selectedSunMonth = selectedSundayFullDate.getMonth() + 1;
        this.selectedSunYear = selectedSundayFullDate.getFullYear();
    }

    getFromDate() {
        return `${this.selectedMonMonth}/${this.selectedMonday}/${this.selectedMonYear}`;
    }

    getToDate() {
        return `${this.selectedSunMonth}/${this.selectedSunday}/${this.selectedSunYear}`;
    }

    getData() {
        const from = this.getFromDate();
        const to = this.getToDate();

        this.collectionsService.getData(from, to).subscribe(data => {
            this.checkData(data, this.setData.bind(this));
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setData(data) {
        this.setNet(data);
        this.setOwed(data);

        if (!this.isDefaultPartnerType()) {
            this.setFeesData(data);
            this.setLicenceData(data);
            this.setTotalOwed(data);
            this.setNetData(data);
            this.setWhiteLabel(data);
        }

        this.settled = !!data.settle;
    }

    setNet(data) {
        this.netTotal = data.moneyIn - data.moneyOut;
        this.net = {
            title: this.isDefaultPartnerType() ? 'Net' : 'Gross Net',
            result: this.netTotal,
            list: [{
                title: this.isDefaultPartnerType() ? 'MONEY IN' : 'IN',
                amount: data.moneyIn
            },
                {
                    title: this.isDefaultPartnerType() ? 'MONEY OUT' : 'OUT',
                    amount: data.moneyOut
                },
            ]
        };
    }

    setOwed(data) {
        const mainTitle = data.owedToLocation < 0 ? this.settleTitle.vendor : this.settleTitle.location;
        const owedTotal = this.isNetTotalNegative() ? 'N/A' : Math.abs(data.owedToLocation);
        this.location = {
            title: this.isDefaultPartnerType() ? mainTitle : null,
            //title: mainTitle,
            result: owedTotal,
            list: [
                {
                    title: 'LOCATION <br> IN CASH',
                    amount: data.locationCash
                },
                {
                    title: 'CASHAPP',
                    amount: data.locationCashapp
                },
                {
                    title: 'VENDOR <br> IN CC',
                    amount: data.vendorCc
                }]
        };
        if (this.isDefaultPartnerType()) {
            this.location.list.push({
                title: 'FEES',
                amount: data.fees ? data.fees : 0
            });
        }
    }

    setFeesData(data) {
        this.feesData = {
            title: 'Total fees',
            result: data.fee ? data.fee : 0,
            // list: []
        };
        if(data.fees){
            this.feesDetailsData = [];
            data.fees.forEach(item => {
                this.feesDetailsData.push({
                    title: item.name,
                    amount: item.amount ? item.amount : 0
                });
            });
        }

    }

    setNetData(data) {
        this.netData = {
            title: 'NET',
            result: data.net ? data.net : 0,
            // list: []
        };
    }

    setWhiteLabel(data) {
        if (data.locationSubtotal) {
            this.whiteLabelData.whiteLabel.percent = data.locationSubtotal.percent;
            this.whiteLabelData.whiteLabel.amount = data.locationSubtotal.amount;
        }
        if (data.locationIn) {
            this.whiteLabelData.inflows.percent = data.locationIn.percent;
            this.whiteLabelData.inflows.amount = data.locationIn.amount;
        }
        if (data.locationOut) {
            this.whiteLabelData.outflows.percent = data.locationOut.percent;
            this.whiteLabelData.outflows.amount = data.locationOut.amount;
        }
        if (data.locationTotal) {
            this.whiteLabelData.total.percent = data.locationTotal.percent;
            this.whiteLabelData.total.amount = data.locationTotal.amount;
        }
    }

    setLicenceData(data) {
        this.licenceData = {
            title: 'Total Licensing',
            result: data.subtotal ? data.subtotal : 0,
            list: []
        };

        if (typeof data.support !== 'undefined') {
            this.licensingDetailsData.push({
                title: `Support`,
                amount: data.support ? data.support : 0
            });
        }

        if (typeof data.customer !== 'undefined') {
            this.licensingDetailsData.push({
                title: `Customer Reward`,
                amount: data.customer ? data.customer : 0
            });
        }

        if (data.games) {
            for (const item in data.games) {
                this.licensingDetailsData.push({
                    title: `${data.games[item].name} (${data.games[item].percent}%)`,
                    amount: data.games[item].amount
                });
            }

        }
    }

    setTotalOwed(data) {
        const mainTitle = data.locationDueTotal < 0 ? 'CURRENT ' + this.settleTitle.vendor : 'CURRENT ' + this.settleTitle.location;
        const owedTotal = this.isNetTotalNegative() ? 'N/A' : Math.abs(data.locationDueTotal);
        this.totalOwed = {
            title: mainTitle,
            result: owedTotal,
        };


        let totalAllTime = data.totalAllTime ? data.totalAllTime : 0;
        this.allTimeOwed = {
            title: totalAllTime < 0 ? 'since last settlement ' + this.settleTitle.vendor : 'since last settlement ' + this.settleTitle.location,
            result: this.isNetTotalNegative() ? 'N/A' : Math.abs(totalAllTime),
        };
    }

    isNetTotalNegative() {
        return this.netTotal < 0;
    }

    checkData(data, successHandler) {
        try {
            if (data.status) {
                successHandler(data);
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onSubmit() {
        this.collectionsService.onSettle().subscribe(data => {
            this.checkData(data, this.onShopPinPopup.bind(this));
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    onShopPinPopup(data) {
        this.onShowSettlePopup(this.PIN_POPUP_TYPE.pin, data)
    }

    onShowSettlePopup(type, data) {
        this.showSettlePopup = true;
        this.settlePopupType = type;
        this.settlePopupData = data;
        this.blurMenuService.blur();
    }

    slideTo(next?) {

        const date = (<HTMLInputElement>document.querySelector('[formcontrolname=date]')).value;
        const dateType = new Date(date);
        let diff;

        if (next) {
            diff = +7;
        } else {
            diff = -7;
        }

        const newDate = new Date(dateType.getFullYear(), dateType.getMonth(), dateType.getDate() + diff)
        this.dateForm.controls.date.setValue(newDate);

        this.updateSelectedPeriod(newDate);
    }

  selectDate($event) {
      this.updateSelectedPeriod($event.value);
  }

    openDatepicker($event) {
      setTimeout(() => {
        const parent = document.querySelector('.mat-calendar-body-active').parentElement;

        parent.querySelectorAll('.mat-calendar-body-cell').forEach(el => {
          el.classList.add('is-selected-week')
        });

      });

    }

    onShowPopupInfo(date?) {
        let from = this.getFromDate();
        let to = this.getToDate();

        if (date) {
            from = date;
            to = date;
        }

        this.paymentsPopupDate = {
            from: from,
            to: to
        };

        this.collectionsService.getDetails(from, to).subscribe(data => {
            this.checkData(data, this.openDetailsPopup.bind(this));
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    onShowDetailsPopup(type, data) {
        this.showPaymentsPopup = true;
        this.paymentsPopupType = type;
        this.paymentsPopupData = data;
        this.blurMenuService.blur();
    }

    openDetailsPopup(data) {
        const from = this.paymentsPopupDate.from;
        const to = this.paymentsPopupDate.to;

        if (from === to) {
            this.onShowDetailsPopup(this.PAYMENTS_INFO_POPUP_TYPE.daily, data);
        } else {
            this.onShowDetailsPopup(this.PAYMENTS_INFO_POPUP_TYPE.weekly, data);
        }
    }



    onSettlePopupClose(info) {
        if (info && info.status) {
            switch (info.type) {
                case this.PIN_POPUP_TYPE.pin: {
                    this.confirmPin(info.pin);
                    break;
                }
                case this.PIN_POPUP_TYPE.confirm: {
                    this.confirmSettle();
                    this.resetSettlePopup();
                    break;
                }
            }
        } else {
            this.resetSettlePopup();
        }
    }

    resetSettlePopup() {
        this.showSettlePopup = false;
        this.settlePopupType = null;
        this.settlePopupData = {};
        this.blurMenuService.focus();
    }

    confirmPin(pin) {
        const from = this.getFromDate();
        const to = this.getToDate();

        this.collectionsService.onSettleVerify(from, to, pin).subscribe(data => {
            this.checkData(data, this.showSettleConfirmPopup.bind(this));
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    confirmSettle() {
        const from = this.getFromDate();
        const to = this.getToDate();

        this.collectionsService.onSettleConfirm(from, to).subscribe(data => {
            this.checkData(data, this.showSuccessSettle.bind(this));
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    showSettleConfirmPopup(data) {
        this.resetSettlePopup();
        this.onShowSettlePopup(this.PIN_POPUP_TYPE.confirm, data);
    }

    showSuccessSettle(data) {
        this.popupComponent.showPopupSuccess({text: data.message});
        this.settled = true;
    }

    isSettleDisabled() {
        //return !!(this.location as any).result
        return new Date() < new Date(this.selectedSunYear, this.selectedSunMonth - 1, this.selectedSunday) || this.isNetTotalNegative();
    }

    isSettled() {
        return this.settled;
    }

    onPaymentsPopupClose(info) {
        this.showPaymentsPopup = false;
        this.paymentsPopupType = null;
        this.paymentsPopupData = {};
        this.blurMenuService.focus();

        if (info.status) {
            switch (info.type) {
                case this.PAYMENTS_INFO_POPUP_TYPE.daily: {
                    if (info.date) {
                        this.onShowPopupInfo(info.dateFormat);

                        this.dateForm.controls.date.setValue(info.date);
                        this.updateSelectedPeriod(info.date);
                    } else {
                        const date = this.getFromDate();
                        this.onShowPopupInfo(date);
                    }

                    break;
                }
                case this.PAYMENTS_INFO_POPUP_TYPE.weekly: {
                    this.onShowPopupInfo();
                    break;
                }
            }
        }
    }

    isDefaultPartnerType() {
        return !this.partnerType || this.partnerType !== '1';
    }

    onCloseMoreInfoPopup() {
        this.isMoreInfoPopup = false;
        this.blurMenuService.focus();
        setTimeout(() => {
            this.moreInfoPopupData = {};
            this.moreInfoPopupTitle = '';
        }, 1000);
    }

    onMoreInfoClick(id) {
        this.blurMenuService.blur();
        this.isMoreInfoPopup = true;
        this.moreInfoPopupTitle = id === 'fees' ? 'FEES' : 'LICENSING';
        this.moreInfoPopupData = {
          title: null,
          result: 1,
          list: id === 'fees' ? this.feesDetailsData : this.licensingDetailsData
        };
    }
}
