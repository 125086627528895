<div class="page" [class.is-blurred]="showSettlePopup || showPaymentsPopup || isMoreInfoPopup">
<div class="page__inner">

        <div class="collections">

            <div *ngIf="!isDefaultPartnerType()" class="top-conclusion">
                <!--<app-conclusion [data]="allTimeOwed"-->
                                <!--[type]="'sm'"></app-conclusion>-->

                <app-conclusion [data]="totalOwed"
                                [type]="'sm'"></app-conclusion>

            </div>

            <h1 class="collections__title">COLLECTIONS WEEK OF</h1>

            <div class="collections__form">

                <form [formGroup]="dateForm" (ngSubmit)="onSubmit()" class="form-simple">
                    <div class="date-slider">
                        <button type="button" (click)="slideTo()" class="date-slider__btn"> < </button>

                        <div class="date-slider__slide">
                            {{selectedMonMonth}}/{{selectedMonday}} - {{selectedSunMonth}}/{{selectedSunday}}
                        </div>

                        <button type="button" (click)="slideTo(true)" class="date-slider__btn"> > </button>
                    </div>

                    <mat-form-field appearance="outline">
                        <input [hidden]="true" (dateChange)="selectDate($event)" formControlName="date" readonly placeholder="From" matInput [matDatepicker]="picker">
                        <mat-datepicker-toggle matSuffix [for]="picker">
                            <svg matDatepickerToggleIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.5338 2.43848H7.46387V3.84468H16.5338V2.43848Z" fill="white"/>
                                <path d="M6.05727 3.14148H4.66699V3.68846H6.05727V3.14148Z" fill="white"/>
                                <path d="M19.3307 3.14148H17.9404V3.68846H19.3307V3.14148Z" fill="white"/>
                                <path d="M20.7372 2.43848V5.09479H16.5345V3.14155H7.46456V5.09479H3.26189V2.43848H0V6.79766H23.9991V2.43848H20.7372Z" fill="white"/>
                                <path d="M0 8.20386V24.0001H23.9991V8.20386H0ZM5.24512 21.3752H2.92163V19.969H5.24512V21.3752ZM5.24512 18.3285H2.92163V16.9223H5.24512V18.3285ZM5.24512 15.2817H2.92163V13.8755H5.24512V15.2817ZM9.20306 21.3752H6.88003V19.969H9.20306V21.3752ZM9.20306 18.3285H6.88003V16.9223H9.20306V18.3285ZM9.20306 15.2817H6.88003V13.8755H9.20306V15.2817ZM13.161 21.3752H10.838V19.969H13.161V21.3752ZM13.161 18.3285H10.838V16.9223H13.161V18.3285ZM13.161 15.2817H10.838V13.8755H13.161V15.2817ZM13.161 12.235H10.838V10.8288H13.161V12.235ZM17.119 21.3752H14.796V19.969H17.119V21.3752ZM17.119 18.3285H14.796V16.9223H17.119V18.3285ZM17.119 15.2817H14.796V13.8755H17.119V15.2817ZM17.119 12.235H14.796V10.8288H17.119V12.235ZM21.0774 18.3285H18.7539V16.9223H21.0774V18.3285ZM21.0774 15.2817H18.7539V13.8755H21.0774V15.2817ZM21.0774 12.235H18.7539V10.8288H21.0774V12.235Z" fill="white"/>
                                <path d="M6.05727 0H4.66699V3.68845H6.05727V0Z" fill="white"/>
                                <path d="M19.3307 0H17.9404V3.68845H19.3307V0Z" fill="white"/>
                            </svg>
                        </mat-datepicker-toggle>
                        <mat-datepicker (opened)="openDatepicker($event)" #picker></mat-datepicker>
                    </mat-form-field>

                    <app-conclusion [data]="net"></app-conclusion>

                    <app-conclusion [data]="location"
                                    [type]="'sm'"></app-conclusion>

                    <ng-container  *ngIf="!isDefaultPartnerType()">
                        <app-conclusion [data]="feesData"
                                        [hasInfo]="true"
                                        [infoId]="'fees'"
                                        (onMoreInfoClick)="onMoreInfoClick($event)"
                                        [type]="'sm'"></app-conclusion>
                        <div *ngIf="!isDefaultPartnerType()" class="top-conclusion top-conclusion--mt">
                            <app-conclusion [data]="licenceData"
                                            [hasInfo]="true"
                                            [infoId]="'licensing'"
                                            (onMoreInfoClick)="onMoreInfoClick($event)"
                                            [type]="'sm'"></app-conclusion>

                            <app-conclusion [data]="netData"
                                            [type]="'sm'"></app-conclusion>
                        </div>
                    </ng-container>


                    <div class="collections__more-info-btn" *ngIf="!isRiverPay && isDefaultPartnerType()">
                        <div class="more-info">
                            <button class="more-info"
                                    type="button"
                                    (click)="onShowPopupInfo()">i</button>
                        </div>
                    </div>

                    <div class="form-simple__btns" [hidden]="true">
                        <button type="submit"
                                [disabled]="isSettleDisabled() || isSettled()"
                                [class.is-disabled]="isSettleDisabled()"
                                [class.is-settled]="isSettled()"
                                class="button snake-btn is-notouched">
                            SETTLE
                            <svg><rect></rect></svg>
                        </button>
                    </div>

                    <div class="result-table" *ngIf="!isDefaultPartnerType()">
                        <table class="result-table__table">
                            <tbody>
                                <tr>
                                    <td>White Label</td>
                                    <td>{{whiteLabelData.whiteLabel.percent}}%</td>
                                    <td>{{whiteLabelData.whiteLabel.amount | currency}}</td>
                                </tr>
                                <tr>
                                    <td>Inflows</td>
                                    <td>{{whiteLabelData.inflows.percent}}%</td>
                                    <td>{{whiteLabelData.inflows.amount | currency}}</td>
                                </tr>
                                <tr>
                                    <td>Outflows</td>
                                    <td>{{whiteLabelData.outflows.percent}}%</td>
                                    <td>{{whiteLabelData.outflows.amount | currency}}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td>{{whiteLabelData.total.percent}}%</td>
                                    <td>{{whiteLabelData.total.amount | currency}}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<app-pin-popup [showPopup]="showSettlePopup"
               [popupType]="settlePopupType"
               [data]="settlePopupData"
                (close)="onSettlePopupClose($event)"></app-pin-popup>

<app-payments-info-popup [showPopup]="showPaymentsPopup"
                         [popupType]="paymentsPopupType"
                         [period]="paymentsPopupDate"
                         [data]="paymentsPopupData"
                         (close)="onPaymentsPopupClose($event)"
                         *ngIf="!isRiverPay"
></app-payments-info-popup>


<div class="more-info-popup is-active" [class.is-active]="isMoreInfoPopup">

    <div class="more-info-popup__inner">
        <button class="more-info-popup__close" (click)="onCloseMoreInfoPopup()">
            <svg viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
                <use xlink:href="../../../../assets/images/sprite.svg#close-sign"></use>
            </svg>
        </button>

        <div class="more-info-popup__title">{{moreInfoPopupTitle}}</div>
        <app-conclusion [data]="moreInfoPopupData"
                        [type]="'sm'"></app-conclusion>
    </div>

</div>
